<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <c-header-nav *ngIf="!viewer" class="d-none d-lg-flex me-auto">
      <c-nav-item>
        <c-dropdown variant="nav-item" [popper]="false">
          <a [routerLink]="[]" cDropdownToggle cNavLink style="color: rgba(1, 33, 65, 0.932);"><b>Opções de Pesquisa</b></a>
          <ul cDropdownMenu dark>
            <li>
              <button
                cNavLink
                routerLink="fichas/pi1"
                cDropdownItem
              >
                P.I1 - Criminal
              </button>
            </li>
            <li>
              <button
                cNavLink
                routerLink="fichas/pi2"
                cDropdownItem
              >
                P.I2 - Comercial PF
              </button>
            </li>
            <li>
              <button
                cNavLink
                routerLink="fichas/pi3"
                cDropdownItem
              >
                P.I3 - Comercial PJ
              </button>
            </li>
            <li>
              <button
                cNavLink
                routerLink="fichas/pi4"
                cDropdownItem
              >
                P.I4 - CNH
              </button>
            </li>
            <li>
              <button
                cNavLink
                routerLink="fichas/pc1"
                cDropdownItem
              >
                P.C1 - Criminal + Comercial PF + CNH
              </button>
            </li>
            <li>
              <button
                cNavLink
                routerLink="fichas/pc2"
                cDropdownItem
              >
                P.C2 - Criminal + Comercial PF
              </button>
            </li>
            <li>
              <button
                cNavLink
                routerLink="fichas/pc3"
                cDropdownItem
              >
                P.C3 - Criminal + CNH
              </button>
            </li>
            <li>
              <button
                cNavLink
                routerLink="fichas/pc4"
                cDropdownItem
              >
                P.C4 - Comercial PF + CNH
              </button>
            </li>
          </ul>
        </c-dropdown>
      </c-nav-item>
      <c-nav-item *ngIf="isAdminUser">
        <a cNavLink routerLink="/usuarios" routerLinkActive="active">Gestão de Usuários</a>
      </c-nav-item>
    </c-header-nav>

    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>
  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
      <c-avatar
        cTextColor="primary"
        shape="rounded-1"
        size="md"
        src="../../../../../assets/images/unknown_user.png"
        status="success"
      >
      </c-avatar>
      {{ userLogin?.username }}
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Configurações</h6>
      </li>
      <li></li>
      <li>
        <a href="change-password" cDropdownItem>
          <svg cIcon class="me-2" name="cil-settings"></svg>
          Alterar senha
        </a>
      </li>
      <li>
        <a (click)="logout()" cDropdownItem>
          <svg cIcon class="me-2" name="cilChevronLeft"></svg>
          Logout
        </a>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template>
