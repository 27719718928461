import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { HeaderComponent as HC } from "@coreui/angular";
import { User } from "src/app/core/models/User.model";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent extends HC {
  @Input() sidebarId: string = "sidebar";
  isAdminUser: boolean = true;
  userLogin: User | undefined;
  viewer: boolean | undefined = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    super();
  }
  
  ngOnInit() {
    this.checkAdminUser();
  }

  private checkAdminUser() {
    var token = localStorage.getItem('token');
    if(token) {
      this.userLogin = this.authService.getUser(token);
      this.isAdminUser = this.userLogin.roles?.find(elem => elem === 'Admin' || elem === 'AdminSolicitacoes' || 
      elem === 'AdminFaturamento' || elem === 'AdminFinanceiro' || elem === 'AdminFerramentas' ||
      elem === 'AdminIntegracoes' || elem === 'AdminClientes' || elem === 'AdminComunicados' || elem === 'AdminUsuarios') == undefined ? false : true;

      if (this.userLogin.roles) {
        if (this.userLogin.roles.length == 1) {
          if (this.userLogin.roles[0] == 'ClienteViewer') {
            this.viewer = true;
          }
        }
      }
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["login"], {
      queryParams: { logoutReason: "Logout realizado com sucesso." },
    });
  }
}
